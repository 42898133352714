<template>
  <div class="profile">
    <div v-if="userProfile">
      <div style="display: flex">
        <div
          class="eh-flex-container"
          style="align-items: center; width: fit-content"
        >
          <b-button v-if="!profileEditable" @click="profileEditable = true">
            {{ $t("partner.participantList.personalDataTab.editProfile") }}
          </b-button>
          <b-button v-if="profileEditable" @click="saveUserPersonalProfile">
            {{ $t("base.basic.save") }}
          </b-button>
          <b-button v-if="profileEditable" @click="cancel">{{
            $t("base.basic.cancel")
          }}</b-button>
        </div>
        <fp-delete-participant-manager
          style="margin-left: auto"
          :projectId="project ? project.ProjectId : ''"
          :participant="participant"
          @refreshList="$emit('refreshList')"
          :managerType="$enums.SystemParameters.ParticipantManagerType.Partner"
        />
      </div>
      <div
        v-if="$productSettings.Partner.ParticipantList.HasParticipantTag"
        style="margin-top: 15px; display: flex; flex-wrap: wrap"
      >
        <div style="display: flex; flex-wrap: wrap">
          <div v-for="(tag, index) in participant.Tags" :key="'tag-' + index">
            <fp-tag :tag="tag" />
          </div>
        </div>
        <fp-svg-icon
          @click="showEditPatientTagModal = true"
          style="cursor: pointer; padding: 1px; margin: 5px"
          :size="30"
          v-b-tooltip.hover="
            $t('healthProfessional.patientList.personalDataTab.editTag')
          "
          icon="pencil"
        />
        <fp-tag-selector
          v-model="showEditPatientTagModal"
          :tagListType="$enums.Tag.TagListType.Both.Value"
          :participant="participant"
          @changePatientList="$emit('refreshList')"
        />
      </div>
      <div style="width: 90%; margin: auto; padding: 50px 0px">
        <health-professional-profile
          :userDataChanged.sync="userDataChanged"
          :clipperImg="clipperImg"
          :username="participant.Username"
          @setProfilePicture="changeProfilePicture"
          :disabled="!profileEditable"
          v-model="userProfile"
        />
      </div>
    </div>
    <div style="text-align: center; margin: 20px" v-else>
      <b-spinner />
    </div>
  </div>
</template>
<script>
import { AttachmentLogic } from "../../../Logic/Backend/attachment";
import { UserProfileLogic } from "../../../Logic/Backend/user-profile";
import HealthProfessionalProfile from "../../Profile/HealthProfessionalProfile.vue";
import Tag from "@/Modules/Tag/Tag.vue";

export default {
  components: {
    HealthProfessionalProfile,
    Tag,
  },
  data() {
    return {
      userProfile: null,
      clipperImg: null,
      userFormBuilder: null,
      tagListById: null,
      participantTagList: this.participant.Tags,
      profileEditable: false,
      profileImgCanvas: null,
      showUserSetting: null,
      showEditPatientTagModal: null,
      userDataChanged:false
    };
  },
  props: {
    participant: Object,
    project: Object,
  },
  watch: {
    "participant.Tags": async function (input) {
      this.participantTagList = input;
      if (input) {
        //profile adatok frissítés
        await this.getUserProfile();
      }
    },
    "participant.Username": async function (input) {
      if (input) {
        //profile adatok frissítés
        await this.getUserProfile();
      }
    },
    async participant(input) {
      this.profileEditable = false;
      if (input) {
        //profile adatok frissítés
        await this.getUserProfile();
      }
    },
  },
  methods: {
    //profil adatok kérés
    async getUserProfile() {
      //console.log("getProfile");
      //kérés indítása
      const profileResponse = await UserProfileLogic.getOtherProfile(
        this.participant.UserId
      );
      //sikeres kérés?
      if (!profileResponse.Code) {
        //kapott profil adat tárolás
        this.userProfile = profileResponse;
        this.userDataChanged = true;
        //profil kép tárolás kép id alapján vagy default megadása
        this.clipperImg = this.userProfile.PersonalProfile.ProfilePictureId
          ? await this.getUserProfilePicture(
              this.userProfile.PersonalProfile.ProfilePictureId
            )
          : require("@/Assets/Default/annonym-profil-icon.png");
      } else {
        this.$bvToast.toast(profileResponse.Message, {
          title: this.$t("requestResponse.basic.errorGetNamedData", {
            name: this.$t("partner.participantList.personalDataTab.errorName"),
          }),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
    //profil kép kérés
    async getUserProfilePicture(pictureId) {
      //kérés indítás
      const pictureResponse = await AttachmentLogic.downloadAttachment(
        pictureId
      );
      //sikeres kérés?
      if (!pictureResponse.Code) {
        //kapott kép blob tárolása
        const imgBlob = await pictureResponse.blob();
        //vissza térés az adott blob alapján generált url-lel
        return URL.createObjectURL(imgBlob);
      } else {
        this.$bvToast.toast(pictureResponse.Message, {
          title: this.$t(
            "requestResponse.attachment.errorDownloadProfilePhoto"
          ),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
        //visszatérés a default kép url-lel
        return require("@/Assets/Default/annonym-profil-icon.png");
      }
    },
    async changeProfilePicture(canvas) {
      this.profileImgCanvas = canvas;
    },
    //profil személyes adatok, profilkép mentése
    async saveUserPersonalProfile() {
      /* const validation = await this.$refs.personalProfile.$validate();
      if (validation) { */
      //kép feltöltési válasz
      var uploadResult = {};
      //van új profilkép canvas?
      if (this.profileImgCanvas) {
        //va, canvas előkészítése a blob készítésre
        const blobImg = await new Promise((resolve) =>
          this.profileImgCanvas.toBlob(resolve)
        );
        //képet blobbá alakítjuk
        var blob = new Blob([blobImg]);
        //sikeres blob?
        if (blob) {
          //kép feltöltés kérés
          uploadResult = await AttachmentLogic.uploadAttachment(
            blob,
            this.$enums.AttachmentTypeName.UserProfile.TypeName,
            this.participant[
              this.$enums.AttachmentTypeName.UserProfile.EntityIdValueKey
            ]
          );
        }
        //sikeres kép feltöltés kérés?
        if (!uploadResult.Code) {
          //előző profilkép file id tárolása szövegként
          const previousProfilePictureId =
            this.userProfile.PersonalProfile.ProfilePictureId;
          //új profilkép file id beállítás a profilban
          this.userProfile.PersonalProfile.ProfilePictureId =
            uploadResult.AttachmentId;
          //profil mentés kérés
          await this.editProfile();
          //van előző profilkép file id?
          if (previousProfilePictureId) {
            //előző profilkép file törlés kérés
            await AttachmentLogic.deleteAttachment(previousProfilePictureId);
          }
        } else {
          this.$bvToast.toast(uploadResult.Message, {
            title: this.$t(
              "requestResponse.attachment.errorUploadProfilePhoto"
            ),
            variant: "danger",
            solid: true,
            AutoHideDelay: 10000,
          });
        }
      } else {
        //nincs, profil adatok mentés kérés
        await this.editProfile();
      }
      /* } else {
        this.validationErrors = this.$refs.personalProfile.validation.errors;
        this.$bvToast.show("validationErrors");
      } */
    },
    async editProfile() {
      const editProfileResponse = await UserProfileLogic.setUserProfile(
        {
          PersonalProfile: this.userProfile.PersonalProfile,
          HealthProfessionalProfile: this.userProfile.HealthProfessionalProfile,
        },
        this.participant.UserId
      );
      if (!editProfileResponse.Code) {
        this.profileEditable = false;
        this.$bvToast.toast(
          this.$t("requestResponse.basic.successModifySave"),
          {
            title: this.$t("requestResponse.basic.successSave"),
            variant: "success",
            solid: true,
          }
        );
        await this.getUserProfile();
      } else {
        this.$bvToast.toast(editProfileResponse.Message, {
          title: this.$t("requestResponse.basic.erroModifySave"),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
    async cancel() {
      this.profileEditable = false;
      await this.getUserProfile();
    },
    //beteg kapcsolat törlése
    deleteRelationship() {
      //parent eventre feliratkozás paraméterrel
      this.$emit("delete-relationship", this.participant.RelationshipId);
    },
  },
  //betöltéskor egyszer lefut
  async mounted() {
    //profil adatok betöltése
    await this.getUserProfile();
  },
};
</script>
